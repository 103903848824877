import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/navbar"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Img from "gatsby-image"
import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"
import Portfolio from "../components/portfolio"
import SEO from "../components/seo"
import imgAndroid from "../images/android-icon.png"
import imgAdobeXd from "../images/adobe-xd-icon.png"
import imgGatsby from "../images/gatsby-icon.png"
import imgFigma from "../images/figma-icon.png"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const android = frontmatter.android ? 'true' : 'false'
  const adobeXd = frontmatter.adobeXd ? 'true' : 'false'
  const figma = frontmatter.figma ? 'true' : 'false'
  const gatsby = frontmatter.gatsby ? 'true' : 'false'
  const isUrl = frontmatter.isUrl ? 'url_true' : 'url_false'
  const isDownloadUrl = frontmatter.isDownloadUrl ? 'url_true' : 'url_false'
  const isDownloadApk = frontmatter.isDownloadApk ? 'url_true' : 'url_false'

  const options = {
    buttons: {
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: true,
    }
  }

  // checkVisitButton {
  //   if(!frontmatter.isDownloadUrl) {
  //     const visitButton = document.getElementById('visit')
  
  //     console.log(frontmatter.isDownloadUrl)
  //     if(visitButton) {
  //       console.log(visitButton)
  //       visitButton.remove('button_outline')
  //       //visitButton.classList.add('button-download')
  //     }
  //   } else {
  //     const visitButton = document.getElementById('visit')
  
  //     console.log(frontmatter.isDownloadUrl)
  //     if(visitButton) {
  //       visitButton.classList.toggle('button_outline')
  //       //visitButton.classList.add('button-download')
  //     }
  //   }
  
  // }

  return (
    <>
      <SEO title={frontmatter.title} image={frontmatter.ogImageUrl.childImageSharp.fluid.src} />
      <SimpleReactLightbox>
        <div>
          <a href="#" className="scrolltop" id="scroll-top">
            <i className="bx bx-chevron-up scrolltop__icon"></i>
          </a>
          <Navbar />
          <div className="l-main">
            <div className="bd-container">
              <Img fluid={frontmatter.imageUrl.childImageSharp.fluid} className="portfolio__img" />
              <div>
                <h2 className="portfolio__title">{frontmatter.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <p className={android}><img src={imgAndroid} className="portfolio__android_icon" alt="" />Android</p>
                <p className={gatsby}><img src={imgGatsby} className="portfolio__adobe_icon" alt="" />Gatsby</p>
                <p className={adobeXd}><img src={imgAdobeXd} className="portfolio__adobe_icon" alt="" />Adobe Xd</p>
                <p className={figma}><img src={imgFigma} className="portfolio__adobe_icon" alt="" />Figma</p>

                <div class="button-download__container">
                  <a href={frontmatter.downloadUrl} class={"button-download " + isDownloadUrl} target="_blank" rel="noopener noreferrer">Download</a>
                  <a href={`${"https://andigoh.com"}/${frontmatter.downloadApk}`} class={"button-download " + isDownloadApk} download>Download</a>
                  <a href={frontmatter.url} class={"button-outline " + isUrl} target="_blank" rel="noopener noreferrer" id="visit">Visit</a>
                </div>
              </div>

            </div>
            <div className="screenshot-area sub-container">
              <div className="about section bd-container">
                <h2 className="screenshot__title">Screenshot</h2>
                <SRLWrapper options={options}>
                  <div className="screenshot__container bd-grid">
                    <a href={frontmatter.screenshot1Url.childImageSharp.fluid.src}>
                      <Img fluid={frontmatter.screenshot1Url.childImageSharp.fluid} className="screenshot__img" />
                    </a>
                    <a href={frontmatter.screenshot2Url.childImageSharp.fluid.src}>
                      <Img fluid={frontmatter.screenshot2Url.childImageSharp.fluid} className="screenshot__img" />
                    </a>
                    <a href={frontmatter.screenshot3Url.childImageSharp.fluid.src}>
                      <Img fluid={frontmatter.screenshot3Url.childImageSharp.fluid} className="screenshot__img" />
                    </a>
                    <a href={frontmatter.screenshot4Url.childImageSharp.fluid.src}>
                      <Img fluid={frontmatter.screenshot4Url.childImageSharp.fluid} className="screenshot__img" />
                    </a>
                    <a href={frontmatter.screenshot5Url.childImageSharp.fluid.src}>
                      <Img fluid={frontmatter.screenshot5Url.childImageSharp.fluid} className="screenshot__img" />
                    </a>
                  </div>
                </SRLWrapper>
              </div>
            </div>
            <Portfolio />
            <Contact />
            <Footer />
          </div>
        </div>
      </SimpleReactLightbox>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        imageUrl {
          childImageSharp {
            fluid(maxWidth: 2024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        screenshot1Url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        screenshot2Url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        screenshot3Url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        screenshot4Url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        screenshot5Url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        ogImageUrl {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        android
        adobeXd
        figma
        gatsby
        isUrl
        url
        isDownloadUrl
        downloadUrl
        isDownloadApk
        downloadApk
      }
    }
  }
`